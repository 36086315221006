




import LookUp from '@/components/lookup/Index.vue'
import { authPermissions } from '@/constants'
import Vue from 'vue'

export default Vue.extend({
    name: 'Unit',
    components: {
        LookUp
    },
    data(){
        return {
            options:{
                name: 'units',
                api: '/PropertyUnit/GetPropertyUnits',
                exportAPI: {
                    api: '/PropertyUnit/ExportPropertyUnits',
                    requiredPermissions: [authPermissions.canExportAll]
                },
                pivotTableAPI: {
                    api:'',
                    params: {
                        source: ''
                    }},
                fields:[
                    {
                        'name': 'property',
                        'alias': 'Business Unit',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':0,
                        'method':'linkSelf',
                        'url':'property'
                    },
                    {
                        'name': 'floor',
                        'alias': 'Floor',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':1
                    },
                    {
                        'name': 'unit',
                        'alias': 'Unit',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':2
                    },
                    {
                        'name': 'currentUnitGLA',
                        'alias': 'GLA',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':3,
                        'method':'formatNumber'
                    },
                    {
                        'name': 'currentLease',
                        'alias': 'Lease',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':4
                    },
                    {
                        'name': 'leaseType',
                        'alias': 'Lease Type',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':5
                    },
                    {
                        'name': 'currentUnitStatusCode',
                        'alias': 'Status',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':6
                    },
                    {
                        'name': 'currentTenantNumber',
                        'alias': 'Tenant #',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':7
                    },
                    {
                        'name': 'currentTenantName',
                        'alias': 'Tenant',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':8
                    },
                    {
                        'name': 'currentTenantLED',
                        'alias': 'Expiry Date',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':9,
                        'method':'formatDate'
                    },
                    {
                        'name': 'minRent',
                        'alias': 'Min Rent',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':10,
                        'method':'formatCurrency'
                    },
                    {
                        'name': 'monthlyRent',
                        'alias': 'Monthly Rent',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':11,
                        'method':'formatCurrency'
                    },
                    {
                        'name': 'annualBaseRentFromLeaseContract',
                        'alias': 'Total Annual Rent',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':12,
                        'method':'formatCurrency'
                    },
                    {
                        'name': 'futureTenantDealType',
                        'alias': 'Deal Type',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':13
                    },
                    {
                        'name': 'futureTenantDealStage',
                        'alias': 'Deal Stage',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':14
                    },
                    {
                        'name': 'futureTenantName',
                        'alias': 'Future Tenant',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':15
                    },
                    {
                        'name': 'futureTenantLCD',
                        'alias': 'LCD',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':16,
                        'method':'formatDate'

                    },
                    {
                        'name': 'futureTenantDealProbability',
                        'alias': 'Probability',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':17,
                        'method':'formatPercentage'
                    },
                ],
                keywordSearchPlaceholder: 'Enter Company Code or Property Code or Tenant # or Tenant or Future Tenant'
            }
        }
    },
})
